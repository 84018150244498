const AuthUtility = {
	setAuthData: (first_name, last_name, email, pay_type, profile_subscription, payment_status, token, role, password, remember_me) => {

		localStorage.setItem('auth_first_name', first_name);
        localStorage.setItem('auth_last_name', last_name);
        localStorage.setItem('auth_email', email);

		if(pay_type && profile_subscription && payment_status){
			localStorage.setItem('auth_pay_type', pay_type);
			localStorage.setItem('auth_profile_subscription', profile_subscription);
			localStorage.setItem('auth_payment_status', payment_status);
		}

        localStorage.setItem('auth_token', token);
        localStorage.setItem('auth_role', role);
        
        if (typeof remember_me === 'boolean') {
			if(remember_me){
	        	localStorage.setItem('password', password);
	        	localStorage.setItem('remember_me', 'true');
			}else{
	        	localStorage.removeItem('password');
	        	localStorage.removeItem('remember_me');
			}
		}
	},
	setSubscriptionData: (pay_type, profile_subscription, payment_status) => {
		if(pay_type && profile_subscription && payment_status){
			localStorage.setItem('auth_pay_type', pay_type);
			localStorage.setItem('auth_profile_subscription', profile_subscription);
			localStorage.setItem('auth_payment_status', payment_status);
		}
	},
	clearAuthData: () => {

		if(!localStorage.getItem('remember_me') || localStorage.getItem('remember_me') !== 'true'){
        	localStorage.removeItem('auth_first_name');
        	localStorage.removeItem('auth_last_name');
        	localStorage.removeItem('auth_email');
        	localStorage.removeItem('password');
		}

		localStorage.removeItem('auth_pay_type');
		localStorage.removeItem('auth_profile_subscription');
		localStorage.removeItem('auth_payment_status');
			
        localStorage.removeItem('auth_token');
        localStorage.removeItem('auth_role');
	},
	getAuthData: () => {
	    const first_name = localStorage.getItem('auth_first_name');
	    const last_name = localStorage.getItem('auth_last_name');
	    const email = localStorage.getItem('auth_email');
	    const pay_type = localStorage.getItem('auth_pay_type');
	    const profile_subscription = localStorage.getItem('auth_profile_subscription');
	    const payment_status = localStorage.getItem('auth_payment_status');
	    const token = localStorage.getItem('auth_token');
	    const role = localStorage.getItem('auth_role');

	    return { first_name, last_name, email, pay_type, profile_subscription, payment_status, token, role };
	},
	isLoggedIn: () => {
		if(localStorage.getItem('auth_first_name') && localStorage.getItem('auth_last_name') && localStorage.getItem('auth_email')
		&& localStorage.getItem('auth_token') && localStorage.getItem('auth_role')){
			return true;
		}else{
			return false;
		}
	},
	isSubscribed: () => {
		if(localStorage.getItem('auth_pay_type') && localStorage.getItem('auth_profile_subscription') && localStorage.getItem('auth_payment_status')){
			return true;
		}else{
			return false;
		}
	},
};

export default AuthUtility;
