import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';

import AxiosApiClient from '../../components/utils/AxiosApiClient';
import AuthUtility from '../frontend/auth/AuthUtility';
import LoadingSpinner from '../frontend/LoadingSpinner';
import PayPalButton from './PayPalButton';

import swal from 'sweetalert';

import subscription_icon from '../../assets/frontend/images/subscription_icon.png';
import edit_icon from '../../assets/frontend/images/edit_icon.png';
import logout_icon from '../../assets/frontend/images/logout_icon.png';

function Account(){
	
	const navHistory = useNavigate();
	
	// using hooks
    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
	const [subscriptionInfo, setSubscriptionInfo] = useState({
		pay_type: '',
		profile_subscription: '',
		payment_plan: '',
		payment_status: '',
		payment_amount: 0,
		start_date: '',
		end_date: '',
	});
	const [subscriptionOption, setSubscriptionOption] = useState({
		yearly_payment_amount: 0,
		monthly_payment_amount: 0,
		vip_yearly_payment_amount: 0,
		vip_monthly_payment_amount: 0,
	});

	// Initial call for user list items
	useEffect(() => {
	
		if(!AuthUtility.isLoggedIn()){
				
			//user not authenticated on server so remove from local storage
			AuthUtility.clearAuthData();
				
			navHistory('/');
		}
		
		setIsLoading(true);
		
		if (isMounted) {
			
			console.log('[Account - useEffect] mounted');

			var auth_api = 'phpLaravel';
			if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') !== ''){
				auth_api = localStorage.getItem('auth_api');
			}

			const fetchData = async () => {
				try {
					const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
					await getBearerToken();
					const response = await makeRequestWithHeaders('get', '/api/subscription_info', null, {});

					setApiData(response);
				} catch (error) {
					handleApiError(error);
				}
			};
		
			fetchData();
			console.log('Account subscription amount:', subscriptionInfo.payment_amount);
			
		}else {
			setIsMounted(true);
		}
		
	}, [isMounted]);

	function setApiData(response){
		
		if(response && response.data){

			if(response.data.status === 200){//HTTP_OK
				if(response.data.user_profile.pay_type === 'payee' || response.data.user_profile.pay_type === 'vip'){
					if(response.data.user_profile.subscription === 'subscribed' && response.data.user_payment.status === 'paid'){
						setSubscriptionInfo({...subscriptionInfo, 
							pay_type: response.data.user_profile.pay_type, profile_subscription: response.data.user_profile.subscription, 
							payment_plan: response.data.user_payment.plan, payment_status: response.data.user_payment.status, payment_amount: response.data.user_payment.amount,
							start_date: response.data.user_payment.start_date, end_date: response.data.user_payment.end_date, 
						});
					}else{
						setSubscriptionInfo({...subscriptionInfo, pay_type: response.data.user_profile.pay_type});
						setSubscriptionOption({...subscriptionOption, yearly_payment_amount: response.data.subscription_yearly_payment_amount, monthly_payment_amount: response.data.subscription_monthly_payment_amount,
							vip_yearly_payment_amount: response.data.subscription_vip_yearly_payment_amount, vip_monthly_payment_amount: response.data.subscription_vip_monthly_payment_amount,
						});
					}
				}else if(response.data.user_profile.pay_type === 'free'){

				}
				
			}else if(response.data.status === 404){//HTTP_NOT_FOUND
				//user payment not found
				setSubscriptionInfo({...subscriptionInfo, pay_type: response.data.user_profile.pay_type});
				setSubscriptionOption({...subscriptionOption, yearly_payment_amount: response.data.subscription_yearly_payment_amount, monthly_payment_amount: response.data.subscription_monthly_payment_amount,
					vip_yearly_payment_amount: response.data.subscription_vip_yearly_payment_amount, vip_monthly_payment_amount: response.data.subscription_vip_monthly_payment_amount,
				});
			}else{//more errors
			}
		}

		setIsLoading(false);
	}
	function handleApiError(error){
		console.log('[Account - useEffect] error: ',error + ' back-end api call error');

		setIsLoading(false);
	}

	const handleSubscriptionOption = (subscription_payment_plan, pay_type) => {
		
		var subscription_payment_amount = 0;
		if(subscription_payment_plan == 'yearly'){
			if(pay_type == 'payee'){
				subscription_payment_amount = subscriptionOption.yearly_payment_amount;
			}else if(pay_type == 'vip'){
				subscription_payment_amount = subscriptionOption.vip_yearly_payment_amount;
			}
		}else if(subscription_payment_plan == 'monthly'){
			if(pay_type == 'payee'){
				subscription_payment_amount = subscriptionOption.monthly_payment_amount;
			}else if(pay_type == 'vip'){
				subscription_payment_amount = subscriptionOption.vip_monthly_payment_amount;
			}
		}
		setSubscriptionInfo({...subscriptionInfo, payment_plan: subscription_payment_plan, payment_amount: subscription_payment_amount});
	};

    const handleSubsriptionPaymentComplete = (data) => {
		setSubscriptionInfo({...subscriptionInfo, pay_type: data.user_profile.pay_type, profile_subscription: data.user_profile.subscription, payment_status: data.user_payment.status, start_date: data.user_payment.start_date, end_date: data.user_payment.end_date});
    };

	const logoutSubmit = (event) => {
		event.preventDefault();

		const fetchData = async () => {
			try {
			  const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ backend: 'phpLaravel', token: localStorage.getItem('auth_token') });
			  await getBearerToken();
			  const response = await makeRequestWithHeaders('get', '/api/logout', null, {});

			  setApiDataLogout(response);
			} catch (error) {
			  handleApiErrorLogout(error);
			}
		};
	  
		fetchData();
	}
    function setApiDataLogout(response){
		
		if(response && response.data){
			if(response.data.status === 200){//success

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				//redirect to home page
				navHistory('/');
			}else if(response.data.status === 401){//user was not logged in

				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				swal("Warning",response.data.message,"warning");
					
				navHistory('/login');
			}else{
				console.log('Error 40X: api call failed');
				
				//user not authenticated on server so remove from local storage
				AuthUtility.clearAuthData();
				
				navHistory('/login');
			}
		}
	}
    function handleApiErrorLogout(error){
		//csrf-cookie is outdated
		console.log('logoutSubmit error: ',error + ' csrf-cookie is outdated');
			
		//user not authenticated on server so remove from local storage
		AuthUtility.clearAuthData();
		
		navHistory('/login');
    }
    
	return(
		<div className="body-content bg-fff pt-70l-110m-50s pb-170l-150s">
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x">
					<div className="large-12 medium-12 small-12 cell text-left">
						<div className="font-raleway font-x-large font-weight-700 txt-333 uppercase bb1-333 pb-5">Account</div>
					</div>
				</div>
			</div>
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x bg-fff b1-ddd p-20l-10s">
					<div className="large-12 medium-12 small-6 cell text-left">
						<div className="font-raleway font-large font-weight-700 txt-333 underline uppercase">Subscription</div>
					</div>

					{isLoading ? (
					<div className="large-12 medium-12 small-6 cell text-center">
					<LoadingSpinner paddingClass="pt-10" />
					</div>
					) : (
					subscriptionInfo.profile_subscription == '' && subscriptionInfo.payment_status == '' ? (
						subscriptionInfo.payment_plan == '' && subscriptionInfo.payment_amount == 0 ? (
						<div className="large-12 medium-12 small-12 cell text-center pt-20">
							<div className="font-raleway font-weight-600 txt-333 text-left">
								Please select your subscription option
								{subscriptionInfo.pay_type == 'payee' &&
								<div className="text-center pt-20">
								<Link to="" onClick={() => handleSubscriptionOption('monthly', subscriptionInfo.pay_type)} className="button">${subscriptionOption.monthly_payment_amount} Monthly</Link>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<Link to="" onClick={() => handleSubscriptionOption('yearly', subscriptionInfo.pay_type)} className="button">${subscriptionOption.yearly_payment_amount} Yearly</Link>
								</div>
								}
								{subscriptionInfo.pay_type == 'vip' &&
								<div className="text-center pt-20">
								<Link to="" onClick={() => handleSubscriptionOption('monthly', subscriptionInfo.pay_type)} className="button">${subscriptionOption.vip_monthly_payment_amount} Monthly</Link>
								&nbsp;&nbsp;&nbsp;&nbsp;
								<Link to="" onClick={() => handleSubscriptionOption('yearly', subscriptionInfo.pay_type)} className="button">${subscriptionOption.vip_yearly_payment_amount} Yearly</Link>
								</div>
								}
								{process.env.REACT_APP_ENV == 'development' &&
								<div className="text-center pt-10 font-medium txt-red">The subscriptions are in test mode so you should see values of $1.00 or so</div>
								}
							</div>
						</div>
						) : (
							<>
							<div className="large-12 medium-12 small-12 cell text-center pt-20">
								<div className="font-raleway font-weight-600 txt-333 text-left">
									Please select your payment option for (${subscriptionInfo.payment_amount} {subscriptionInfo.payment_plan}) subscription.
								</div>
							</div>
							<div className="large-3 medium-3 small-12 cell text-center pt-20 hide-for-small-only"></div>
							<div className="large-6 medium-6 small-12 cell text-center pt-20">
								<PayPalButton subscriptionInfo={subscriptionInfo} onSubscriptionPaymentComplete={handleSubsriptionPaymentComplete}/>
							</div>
							<div className="large-3 medium-3 small-12 cell text-center pt-20 hide-for-small-only"></div>
							</>
						)
					) : (

					<div className="large-12 medium-12 small-12 cell text-left pt-20 pr-10">
						<table className="unstriped unbordered mb-0">
							<tbody>
							<tr><td className="font-raleway font-weight-400 width-100px pb-20">Plan:</td><td className="font-raleway font-weight-600 pb-20">{subscriptionInfo.payment_plan}{subscriptionInfo.pay_type == 'vip' ? ' (vip)' : ''}</td></tr>
							<tr><td className="font-raleway font-weight-400 width-100px pb-20">Payment:</td><td className="font-raleway font-weight-600 pb-20">${subscriptionInfo.payment_amount}</td></tr>
							<tr><td className="font-raleway font-weight-400 width-100px pb-20">Valid:</td><td className="font-raleway font-weight-600 pb-20">{subscriptionInfo.start_date} - {subscriptionInfo.end_date}</td></tr>
							</tbody>
						</table>
					</div>
					)
				)}

				</div>
			</div>

			<div className="panel large pt-20l-10s">
				<div className="grid-x bg-fff b1-ddd p-20l-10s">

					<div className="large-6 medium-6 small-6 cell text-left">
						<div className="font-raleway font-large font-weight-700 txt-333 underline uppercase">Profile</div>
					</div>

					<div className="large-6 medium-6 small-6 cell text-right">
						<Link to="../profile" className="button icon"><img src={edit_icon} alt="profile"/></Link>
					</div>

					<div className="large-12 medium-12 small-12 cell text-left pt-10">
						<table className="unstriped unbordered mb-0">
							<tbody>
							<tr><td className="font-raleway font-weight-400 width-100px pb-20">Name:</td><td className="font-raleway font-weight-600 pb-20">{localStorage.getItem('auth_first_name') + ' ' + localStorage.getItem('auth_last_name')}</td></tr>
							<tr><td className="font-raleway font-weight-400 width-100px pb-20">Email:</td><td className="font-raleway font-weight-600 pb-20">{localStorage.getItem('auth_email')}</td></tr>
							<tr><td className="font-raleway font-weight-400 width-100px pb-20">Role:</td><td className="font-raleway font-weight-600 pb-20">{localStorage.getItem('auth_role')}{subscriptionInfo.pay_type == 'vip' ? ' (vip)' : ''}</td></tr>
							<tr><td className="font-raleway font-weight-400 width-100px">Password:</td><td className="font-raleway font-weight-600 ">********</td></tr>
							</tbody>
						</table>
					</div>
					
				</div>
			</div>
		
			<div className="panel large pt-20l-10s">
				<div className="grid-x bg-fff b1-ddd p-20l-10s">

					<div className="large-6 medium-6 small-6 cell text-left">
						<div className="font-raleway font-large font-weight-700 txt-333 underline uppercase">Logout</div>
					</div>

					<div className="large-6 medium-6 small-6 cell text-right">
						<Link onClick={logoutSubmit} className="button icon"><img src={logout_icon} alt="logout"/></Link>
					</div>
					
				</div>
			</div>

		</div>
	);
}

export default Account;