import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';

import AxiosApiClient from '../utils/AxiosApiClient';
import AuthUtility from '../frontend/auth/AuthUtility';

import swal from 'sweetalert';

const PayPalButton = ({subscriptionInfo, onSubscriptionPaymentComplete}) => {

	const navHistory = useNavigate();
	const [message, setMessage] = useState("");

	console.log('PayPalButton member:', subscriptionInfo.pay_type);
	console.log('PayPalButton Subscription plan:', subscriptionInfo.payment_plan);
	console.log('PayPalButton Subscription amount:', subscriptionInfo.payment_amount);
/*
	console.log('PayPalButton REACT_APP_PAYPAL_CLIENT_ID_DEV:', process.env.REACT_APP_PAYPAL_CLIENT_ID_DEV);
	console.log('PayPalButton REACT_APP_PAYPAL_MONTHLY_SUBSCRIPTION_PLAN_ID_DEV:', process.env.REACT_APP_PAYPAL_MONTHLY_SUBSCRIPTION_PLAN_ID_DEV);
	console.log('PayPalButton REACT_APP_PAYPAL_YEARLY_SUBSCRIPTION_PLAN_ID_DEV:', process.env.REACT_APP_PAYPAL_YEARLY_SUBSCRIPTION_PLAN_ID_DEV);
	console.log('PayPalButton REACT_APP_PAYPAL_VIP_MONTHLY_SUBSCRIPTION_PLAN_ID_DEV:', process.env.REACT_APP_PAYPAL_VIP_MONTHLY_SUBSCRIPTION_PLAN_ID_DEV);
	console.log('PayPalButton REACT_APP_PAYPAL_VIP_YEARLY_SUBSCRIPTION_PLAN_ID_DEV:', process.env.REACT_APP_PAYPAL_VIP_YEARLY_SUBSCRIPTION_PLAN_ID_DEV);

	console.log('PayPalButton REACT_APP_PAYPAL_CLIENT_ID:', process.env.REACT_APP_PAYPAL_CLIENT_ID);
	console.log('PayPalButton REACT_APP_PAYPAL_MONTHLY_SUBSCRIPTION_PLAN_ID:', process.env.REACT_APP_PAYPAL_MONTHLY_SUBSCRIPTION_PLAN_ID);
	console.log('PayPalButton REACT_APP_PAYPAL_YEARLY_SUBSCRIPTION_PLAN_ID:', process.env.REACT_APP_PAYPAL_YEARLY_SUBSCRIPTION_PLAN_ID);
	console.log('PayPalButton REACT_APP_PAYPAL_VIP_MONTHLY_SUBSCRIPTION_PLAN_ID:', process.env.REACT_APP_PAYPAL_VIP_MONTHLY_SUBSCRIPTION_PLAN_ID);
	console.log('PayPalButton REACT_APP_PAYPAL_VIP_YEARLY_SUBSCRIPTION_PLAN_ID:', process.env.REACT_APP_PAYPAL_VIP_YEARLY_SUBSCRIPTION_PLAN_ID);
	*/

	var paypal_client_id;
	var paypal_subscription_plan_id;
	if(process.env.REACT_APP_ENV == 'development'){
		paypal_client_id = process.env.REACT_APP_PAYPAL_CLIENT_ID_DEV;
		if(subscriptionInfo.pay_type == 'payee' && subscriptionInfo.payment_plan == 'monthly'){
			paypal_subscription_plan_id = process.env.REACT_APP_PAYPAL_MONTHLY_SUBSCRIPTION_PLAN_ID_DEV;
		}else if (subscriptionInfo.pay_type == 'payee' && subscriptionInfo.payment_plan == 'yearly'){
			paypal_subscription_plan_id = process.env.REACT_APP_PAYPAL_YEARLY_SUBSCRIPTION_PLAN_ID_DEV;
		}else if(subscriptionInfo.pay_type == 'vip' && subscriptionInfo.payment_plan == 'monthly'){
			paypal_subscription_plan_id = process.env.REACT_APP_PAYPAL_VIP_MONTHLY_SUBSCRIPTION_PLAN_ID_DEV;
		}else if (subscriptionInfo.pay_type == 'vip' && subscriptionInfo.payment_plan == 'yearly'){
			paypal_subscription_plan_id = process.env.REACT_APP_PAYPAL_VIP_YEARLY_SUBSCRIPTION_PLAN_ID_DEV;
		}
	}else if(process.env.REACT_APP_ENV == 'production'){
		paypal_client_id = process.env.REACT_APP_PAYPAL_CLIENT_ID;
		if(subscriptionInfo.pay_type == 'payee' && subscriptionInfo.payment_plan == 'monthly'){
			paypal_subscription_plan_id = process.env.REACT_APP_PAYPAL_MONTHLY_SUBSCRIPTION_PLAN_ID;
		}else if (subscriptionInfo.pay_type == 'payee' && subscriptionInfo.payment_plan == 'yearly'){
			paypal_subscription_plan_id = process.env.REACT_APP_PAYPAL_YEARLY_SUBSCRIPTION_PLAN_ID;
		}else if(subscriptionInfo.pay_type == 'vip' && subscriptionInfo.payment_plan == 'monthly'){
			paypal_subscription_plan_id = process.env.REACT_APP_PAYPAL_VIP_MONTHLY_SUBSCRIPTION_PLAN_ID;
		}else if (subscriptionInfo.pay_type == 'vip' && subscriptionInfo.payment_plan == 'yearly'){
			paypal_subscription_plan_id = process.env.REACT_APP_PAYPAL_VIP_YEARLY_SUBSCRIPTION_PLAN_ID;
		}
	}
	console.log('PayPalButton REACT_APP_ENV:', process.env.REACT_APP_ENV);
	console.log('PayPalButton paypal_client_id:', paypal_client_id);
	console.log('PayPalButton paypal_subscription_plan_id:', paypal_subscription_plan_id);

	function subscriptionPreSubmit(){
		subscriptionSubmit('paypal', 'vendor_payer_id placeholder', 'vendor_payer_email placeholder', 'vendor_trx_id placeholder', 'vendor_txn_type placeholder')
	}

	function subscriptionSubmit(vendor, recurring_payment_id){

		//values sent to api
		const payload = {
			plan: subscriptionInfo.payment_plan,
			amount: subscriptionInfo.payment_amount,
			vendor: vendor,
			recurring_payment_id: recurring_payment_id,
		}

		var auth_api = 'phpLaravel';
		if(localStorage.getItem('auth_api') && localStorage.getItem('auth_api') != ''){
			auth_api = localStorage.getItem('auth_api');
		}

        const fetchData = async () => {
            try {
              const { apiClient, getBearerToken, makeRequestWithHeaders } = AxiosApiClient({ apiBackend: auth_api, token: localStorage.getItem('auth_token') });
              await getBearerToken();
              const response = await makeRequestWithHeaders('post', '/api/subscription_payment', payload, {});

              setApiData(response);
            } catch (error) {
              handleApiError(error);
            }
        };
      
        fetchData();
		
	}
    function setApiData(response){
		
		if(response && response.data){

			if(response.data.status === 200){//HTTP_OK

				if(response.data.profile && response.data.payment_paid && response.data.profile.subscription == "subscribed"){
					AuthUtility.setSubscriptionData(response.data.profile.pay_type, response.data.profile.subscription, response.data.payment_paid.status);

					const userPaymentData = {
						user_profile: response.data.profile,
						user_payment: response.data.payment_paid,
					};
		
					// Pass the payment data back to the parent component
					onSubscriptionPaymentComplete(userPaymentData);

					//sweet alert on next page
					swal("Success",response.data.message,"success");
					navHistory('../account');
				}
				
			}else if(response.data.status === 404){//HTTP_NOT_FOUND
				
			}else{//more errors
			}
		}
	}
    function handleApiError(error){
		console.log('subscriptionSubmit error: ',error + ' back-end api call error');
    }

	const initialOptions = {
		//production or sandbox
		"client-id": paypal_client_id,
		"vault": true, // Required for subscription
		"intent": "subscription",
		"currency": "USD"
	};
	return (
		<>
		<PayPalScriptProvider options={initialOptions}>
			<PayPalButtons
				amount={subscriptionInfo.payment_amount}
				style={{
					shape: 'rect',
					color: 'gold',
					layout: 'vertical',
					label: 'subscribe'
				}}
				createSubscription={(data, actions) => {
					return actions.subscription.create({
						plan_id: paypal_subscription_plan_id, // Replace with your actual plan ID
					});
				}}
				onApprove={(data, actions) => {
					console.log('Subscription completed successfully:', data);

					// Handle post-subscription logic here
					var vendor = data.paymentSource; //paypal
					var recurring_payment_id = data.subscriptionID;

					subscriptionSubmit(vendor, recurring_payment_id);

				}}
				onError={(err) => {
					console.error('Subscription error:', err);
					// Handle subscription error here
				}}
			/>
		</PayPalScriptProvider>
		<div className="pt-20">
		<Link onClick={subscriptionPreSubmit} className="button medium width-225px">Subscribe Placeholder</Link>
		</div>
		</>
	);
};

export default PayPalButton;
